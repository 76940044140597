import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "jquery/dist/jquery.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

//------------------------ Owl carousel------------------------
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// -------------------- bootstrap----------------------
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

// ---------------------  fortawesome --------------------
// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
// ---------------------  toastify --------------------
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
library.add(fab, fas, far);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ToastContainer autoClose="2000" theme='dark' transition={Flip}/>
   
    <App />
  </React.StrictMode>
);
