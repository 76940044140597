import React, { useEffect } from "react";
import {
  CommonBanner,
  ContactUsMapView,
  ContactUsPanel,
  Footer,
  GetInTouch,
  Header,
} from "../../components";
import images from "../../assets";
import "./contactus.css";
import { useLocation } from "react-router-dom";
const dataToSend = {
  bannerImg: images["aboutbg"],
  bannerHeading: "Contact us",
  path: [
    {
      link: "/",
      name: "Contact us",
    },
  ],
};

function ContactUs() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);
  return (
    <div>
      <Header />
      <CommonBanner {...dataToSend} />
      <GetInTouch />
      <ContactUsPanel />
      <ContactUsMapView />
      <Footer />
    </div>
  );
}

export default ContactUs;
