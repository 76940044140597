import { HTTPURL } from '../constants/Matcher';
import { toast } from "react-toastify";
export async function get_events() {
    try {
        const response = await fetch(HTTPURL + 'api/student/student-events-all');
        const responseData = await response.json();
        if (responseData.status) {
            // responseData?.message;
        }
        // else {
        //    responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function events_edit(id)
{
    try {
        const response = await fetch(HTTPURL + `api/event-edit?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// events update 
export async function events_register(data)
{
    try {
        const response = await fetch(HTTPURL + `api/event-registration`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        return error.message;
    }
}