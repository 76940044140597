import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { events_edit, events_register } from "../../apis/Dashboard";
const BookEvent = ({ isModalOpen, setIsModalOpen, id }) => {
  const [eventData, setEventData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fetchData = async () => {
    let res = await events_edit(id);
    const event = res?.data[0];
    setEventData(event);
  };
  useEffect(() => {
    if(id){
      fetchData();
    }
  }, [id]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm();
  const onSubmit = async (data) => {
    data.event_id = eventData?.id;
    if (isSubmitting) return;
    setIsSubmitting(true);
    let result = await events_register(data);
    if (result.status) {
      setIsModalOpen(false);
    //   events_edit(eventData?.id);
      reset()
    }
    setIsSubmitting(false);
  };
  
  return (
    <div className="">
      <Modal
        show={isModalOpen}
        onHide={() =>{ setIsModalOpen(false);reset()}}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="event-popup"
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5">Book Seminar</h1>
          <button
            type="button"
            onClick={() => {setIsModalOpen(false);reset()}}
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>
        <div className="seminar">
          <div className="Selective">
            <h3>Selective High School Placement Test information Seminar</h3>
            <div className="date_time">
              <div className="date_spacening">
                <p>Date & Time</p>
                <p>Venue</p>
              </div>
              <div>
                <p>
                  {eventData?.date || "N/A"},{eventData?.time_start || "N/A"}-
                  {eventData?.time_end || "N/A"}, Offline (Face-to-Face)+Online
                </p>
                <p>{eventData?.venue_name || "N/A"}</p>
              </div>
            </div>
          </div>
          <div className="available_seats">
            <p>
              Available seats (offline):{" "}
              <span>{eventData?.offline_available_seats || "N/A"}</span>/
              {eventData?.offine_seats || "N/A"}
            </p>
            <p>
              Available seats (online):{" "}
              <span>{eventData?.online_available_seats || "N/A"}</span>/
              {eventData?.online_seats || "N/A"}
            </p>
            <p>
              <span>**The number of available seats may vary.</span>
            </p>
            <p>
              <span>*Gmail account required for online access.</span>
            </p>
          </div>
          <div className="booking_details">
            <h3>Booking Details</h3>
          </div>

          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="seminar_orm">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label for="exampleInputPassword1" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        {...register("first_name")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label for="exampleInputPassword1" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        {...register("last_name")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label for="exampleInputPassword1" className="form-label">
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        {...register("mobile")}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label for="exampleInputPassword1" className="form-label">
                        Email Address*
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputPassword1"
                        {...register("email", {
                          required: true,
                          pattern:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        })}
                      />
                      {errors.email && errors.email.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                      {errors.email && errors.email.type === "pattern" && (
                        <span className="validation-error">
                          Must be a valid email
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label for="exampleInputPassword1" className="form-label">
                        Name of Your Child
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        {...register("child_name")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label for="exampleInputPassword1" className="form-label">
                      Offline/Online*
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      {...register("event_type")}
                    >
                      <option selected value="offline" > Offline</option>
                      <option  value="online"> Online </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="btn-seminar2">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting}
                >
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BookEvent;
