import React, { useEffect } from "react";
import PolicyBanner from "../../components/PolicyBanner";
import './policy.css';
import { Header,Footer } from "../../components";
import { useLocation } from "react-router-dom";
const Privacy = () => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [location]);
  return (
    <>
    <Header />
      <PolicyBanner title={"Privacy Policy"} />
      <section class="privacy_policy">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="privacy">
                        <h6>Commitment to Privacy</h6>
                        <p>CS Education is committed to protecting your privacy when collecting and handling your
                            personal
                            information. CS Education is bound by the National Privacy Principles under the Privacy Act
                            1988 (Cth) ("Act").</p>
                    </div>
                    <div class="privacy">
                        <h6>Related Organisations</h6>
                        <p>CS Education is an organisation which includes CS Online School ( <a
                                href="https://student.connectedschool.com.au" target="_blank"> NAPLAN, SELECTIVE, OC, HSC Specialis
                                t::CS Online School::</a> ), CS Bookstore (<a href="#">www.csbookstore.com.au</a>) and
                            branch colleges operating in other
                            locations under the CS Education name. <br />Information is shared between these
                            organisations, all of whom are bound by this code of privacy.
                        </p>
                    </div>

                    <div class="privacy">
                        <h6>Collection of Personal Information</h6>
                        <p>CS Education only collects personal information necessary for us to carry out our functions
                            and activities such
                            as:</p>
                        <li>to supply you with goods or services that you have requested;</li>
                        <li>to process your order made through our Web site, or by telephone, fax and mail;</li>
                        <li>to provide you with information you have requested from us;</li>
                        <li>to provide you with information regarding a lesson or test;</li>
                        <li>to enable us to process and determine any request;</li>
                        <li>to provide you with information about new products, special offers, and other services
                            offered by CS
                            Education; and</li>
                        <li>to improve your educational experience.</li>

                        <p>The types of personal information that CS Education collects generally include your name,
                            telephone number,
                            mailing address, delivery address, email address, account details, credit card details and
                            driver's licence
                            number</p>

                        <p>If it is reasonable and practicable to do so, CS Education will collect personal information
                            directly from you.
                            We may collect personal information when you deal with CS Education over the phone, when you
                            send us
                            correspondence (such as letters, faxes and emails), when you register on our Web site, when
                            you submit
                            queries and other forms on our Web site and when you request a catalogue</p>

                        <p>A cookie is a small text file created by a web server and stored on your computer, enabling a
                            unique
                            identification to be given to your computer. Cookies enable our systems to track your clicks
                            as you browse,
                            shop and learn online at our websites and move between pages on our sites. The cookie also
                            helps us greet
                            you personally and keep track of the items in your shopping basket without taking extra time
                            to re-verify
                            your password on each page. We do not use cookies to retrieve or store personal information
                            about you
                            from your computer. Most Internet browsers are pre-set to accept cookies. If you prefer not
                            to receive
                            cookies, you can adjust your Internet browser to disable or to warn you when cookies are
                            used. By disabling
                            cookies, you will not be able to participate in some of the online features offered by CS
                            Education and its
                            associated websites. We recommend that you leave your cookies enabled, otherwise you will
                            not be able to
                            deal with us online.</p>

                        <p>Our service provider makes a record of your visit to our Web site and logs information from
                            your browser
                            including your IP address the date and time of you visit, the pages accessed, documents
                            downloaded and the
                            type of browser used. This is done as a fraud prevention measure, to enable us to evaluate
                            our Web site
                            usage and improve our online service. The information collected is not linked back to
                            individual users except,
                            in the unlikely event of an investigation, where a law enforcement agency may exercise a
                            warrant to inspect
                            the service provider’s logs. In addition, when you use our online search facility we collect
                            information about
                            what you are searching for. CS Education uses that information to evaluate our service and
                            determine if we
                            are providing the products and services you desire. The information collected is not linked
                            back to the
                            individual.
                        </p>

                        <p>Should you not wish to provide the requested personal information, you may purchase products
                            from CS
                            Education by paying in cash in full at the time of purchase. When making a purchase from CS
                            Education via
                            our Web site, if you are an Australian customer, and you would prefer not to enter your
                            credit card details
                            though the internet, you may contact us after you submit your online order to arrange one of
                            the following
                            alternative methods of payment: providing us with your credit card details over the phone;
                            faxing your credit
                            card details to us; mailing a cheque or money order to CS Education or paying via BPay.
                            Alternatively you may organise a pre-arranged account with CS Education, the approval of
                            which is at the
                            discretion of CS Education, and charge your purchase to a 30 day CS Education account</p>
                        <p>If we collect personal information about you from someone else, we will take reasonable steps
                            to ensure that
                            you are aware that we may gain access to that information, the purpose of its collection and
                            the
                            organisations to which that information may be disclosed.</p>

                        <p>Failure to provide us with the requested information, particularly the requested information
                            marked with an
                            asterisk on our Web site, may result in a delay in providing you with goods or services
                            requested from us or
                            may mean that we cannot provide you with those goods or services.
                        </p>
                        <p>CS Education will only collect personal information by lawful and fair means and not in an
                            unreasonably
                            intrusive way.
                        </p>
                    </div>

                    <div class="privacy">
                        <h6>Use and Disclosure of Personal Information</h6>
                        <p>CS Education only uses or discloses personal information it collects about you in compliance
                            with the Act. CS
                            Education does not sell or rent your personal information to any third party without your
                            permission.
                        </p>
                        <p>CS Education will use and disclose your personal information for the purpose of our business
                            as a coaching
                            college or other purposes related to that purpose such as marketing and where you consent to
                            such
                            disclosure. Personal information that you submit to CS Education may be used and disclosed
                            by CS Education
                            to the extent authorised by you or as reasonably required in connection with the operation
                            of our business
                            and in compliance with applicable laws</p>

                        <p>CS Education may disclose personal information it collects to third party organisations such
                            as contractors
                            who assist CS Education in the operation of its business and to enable those contractors to
                            perform functions
                            on behalf of CS Education. For example, your personal information may be supplied to mailing
                            houses to
                            enable the delivery of our mail order catalogues to you. Personal information may also be
                            supplied to third parties to allow credit card payment authorisation, marketing and
                            promotions. Where information is
                            disclosed to such third parties we will prohibit the third party from using your personal
                            information except for
                            the specific purpose for which we supply it.</p>

                        <p>If you submit an application for employment or otherwise participate in any recruitment
                            process undertaken
                            by or on behalf of CS Education, we may (a) use personal information about you (including
                            any health or
                            other sensitive information) for the purposes of assessing your application for employment;
                            and (b) retain
                            that information as part of our employment application records even if we do not offer you
                            employment; and
                            (c) use that information (at our discretion) in connection with other relevant employment
                            opportunities which
                            may arise within CS Education from time to time</p>

                        <p>CS Education may also use the information you have supplied to us to provide you with
                            periodic mailings
                            with information on events, new products and special offers. If you do not wish to receive
                            such mailings
                            please advise us by contacting our Privacy Officer at CS Education, Shop 22 15-17 Terminus
                            St, Castle Hill
                            NSW 2154.
                        </p>
                        <p>Where we receive a request from you with respect to your privacy options as noted above, we
                            will use our
                            reasonable efforts to delete the relevant personal information from our records. However, we
                            cannot
                            guarantee that the information has been deleted as there is a possibility that a residual
                            copy of the
                            information will be maintained on a back-up system.</p>
                    </div>

                    <div class="privacy">
                        <h6>Anonymity</h6>
                        <p>Wherever it is lawful and practicable to do so, CS Education will provide you with the option
                            of not
                            identifying yourself when transacting with us. For example, you may provide us with feedback
                            via our online
                            feedback form, or respond to surveys or direct marketing activities without identifying
                            yourself. However, we
                            require personal information concerning you to provide you with services you have requested,
                            to assess your
                            academic progress, to verify your identity and to deliver the services you have requested.
                        </p>
                        <p>Once you register with CS Education you cease to be anonymous.</p>
                    </div>
                    <div class="privacy">
                        <h6>Quality and Security of your Personal Information</h6>
                        <p>Your personal information is protected from misuse and loss and from unauthorised access,
                            modification or
                            disclosure by a range of security measures including computer network security systems,
                            restricted access
                            and building security systems, firewalls, expert computer monitoring and passwords for
                            checking identity
                            before disclosing information. Information submitted via our Web site is submitted via our
                            secure sites
                            (<a href="https://cseducation.com.au/">www.cseducation.com.au </a> and <a
                                href="https://www.csonlineschool.com.au/main.asp">http://www.csonlineschool.com.au</a> )
                            which use standard technology
                            to ensure
                            your information is kept private. One of the key components of these websites is the use of
                            Secure Sockets
                            Layer (SSL) technology. Information on our Web site is protected by encryption and firewall
                            technology.</p>
                        <p>Only authorised employees are permitted to access your personal information. All employees
                            must comply
                            with our Privacy Policy and those who violate it may be subject to disciplinary action.</p>
                        <p>We will take reasonable steps to implement and maintain security precautions and endeavour to
                            prevent
                            unauthorised access to, disclosure or use of submitted information. However, as no data
                            transmission over the Internet is 100% secure CS Education is unable to guarantee that
                            unauthorised access to submitted
                            information will not occur, either during transmission of that information or after we
                            receive that information</p>
                        <p>CS Education takes reasonable steps to destroy or permanently de-identify any personal
                            information it no
                            longer requires</p>

                        <p>Your Customer Number and password enable CS Education to verify you are a registered customer
                            and to
                            help us to ensure your personal information is kept secure. You are responsible for
                            maintaining the secrecy of
                            your Customer Number and password. We do not disclose your password details via email or
                            telephone
                            other than in limited circumstances in which case you will first be required to provide us
                            with information so
                            that we may verify you identity. If you cannot remember your password you will be required
                            to match a
                            certain level of information that CS Education holds in respect of you so that we may verify
                            your identity and
                            allow you to set a new password.</p>
                        <p>You should sign out or close your browser once you have completed your online activity within
                            our Web sites
                            to ensure others cannot access your personal information.
                        </p>
                    </div>

                    <div class="privacy">
                        <h6>Links to other Sites</h6>
                        <p>The CS Education Web site may contain links to Web sites of other companies and
                            organisations. CS
                            Education is not responsible for the privacy practices or content of such Web sites.</p>
                    </div>

                    <div class="privacy">
                        <h6>Access and Correction of your Personal Information and Data Quality</h6>
                        <p>If you wish to access your personal information held by CS Education, send a written request
                            by post to the
                            Privacy Officer at CS Education. So that CS Education can identify you, please include your
                            full name and
                            address as they appear in our records, your daytime telephone number, your e-mail address if
                            applicable, and
                            the reason why you require access to our record of your personal information. We will
                            provide you with
                            access to the personal information held concerning you upon receiving a request from you
                            except where we
                            may refuse access to that information in conformity with the Act. If we deny your request
                            for access to
                            personal information, we will provide you with the reasons for that denial. We may recover
                            an amount from
                            you to cover our reasonable costs of providing you with access to that personal information.
                        </p>
                        <p>CS Education will take all reasonable steps to make sure that the personal information it
                            collects uses or
                            discloses is accurate, complete and up-to-date. To assist us to do this please provide us
                            with the correct
                            information at the time you provide information to us and inform us if your details have
                            changed. This can be
                            done by contacting our Privacy Officer at CS Education. Make sure you include sufficient
                            information so that
                            CS Education can identify you. If you request that we amend personal information and we
                            disagree with you
                            about whether the information is accurate, complete and up-to-date, you may request that we
                            associate a
                            statement with the record of information as to your contrary view. We will take reasonable
                            steps to comply
                            with that request. If we refuse to correct personal information we will provide you with a
                            reason for such
                            denial. You acknowledge that certain information may be stored in a form which does not
                            readily permit
                            location and modification of that information</p>
                    </div>
                    <div class="privacy">
                        <h6>Transfer of personal information overseas</h6>
                        <p>CS Education will only transfer your personal information overseas if CS Education reasonably
                            believes that the recipient of the information is subject to a law, binding scheme or
                            contract which effectively upholds
                            principles for fair handling of the information that are substantially similar to the
                            National Privacy Principles,
                            or you consent to the transfer, or the transfer is necessary for the performance of a
                            contract between you and
                            CS Education (such as the supply of a service you have requested from us), or the transfer
                            is otherwise in
                            conformity with the Act.
                        </p>
                    </div>
                    <div class="privacy">
                        <h6>Changes to Privacy Policy</h6>
                        <p>CS Education's information practices and Privacy Policy will be monitored and, if necessary,
                            revised from time
                            to time. CS Education will notify you of any amendments to its Privacy Policy by posting an
                            updated version
                            on its Web site<a href="https://connectedschool.com.au/" target="_blank"> https://connectedschool.com.au</a>.</p>
                    </div>
                    <div class="privacy">
                        <h6>Complaint Process</h6>
                        <p>CS Education is committed to dealing quickly and confidentially with any complaint you make
                            about your
                            privacy in relation to the way CS Education collects, uses, discloses, handles or holds your
                            personal
                            information. If you are concerned that this Privacy Policy may have been breached or that
                            your privacy may
                            have been compromised please write to the Privacy Officer at CS Education</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
    </>
  );
};

export default Privacy;
