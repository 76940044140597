// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import images from "../../../assets";
// import { apiRequest, capitalizeFirstLetter } from "../../../services";

// // import useImageImport from "../../../assets/index";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { get_events } from "../../../apis/Dashboard";
// function Footer() {
//   // const images = useImageImport();
//   const [footerData, setFooterDataa] = useState([]);
//   const [events, setEvents] = useState([]);

//   useEffect(() => {
//     fetchData()
//   }, [])

//   useEffect(() => {
//     async function get_data() {
//         const event = await get_events ();
//         setEvents(event?.data);
//     }
//     get_data();
// }, []);

//   const fetchData = async () => {
//     try {
//       const response = await apiRequest('/api/footer-sections/get-list', 'GET');
//       console.log("res", response);
//       setFooterDataa(response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   return (
//     <>
//        {/* Not required for now<div class="footer-icons">
//         <div class="footer-top-icon">
//           <img {...images["pencil-girl"]} />
//         </div>
//         <div class="footer-top-icon-rt">
//           <img {...images["pencil-boy"]} />
//         </div>
//       </div>
//       <div class="footer-border">
//         <img {...images["footer-border"]} />
//   </div> */}
//       <section class="footer-sec">
//         <div class="footer-inner container">
//           <div class="footer-grid-box">
//             <div class="col-md-3 col-12">
//               <div class="footer-logo">
//                 <img {...images["footer-logo"]} />
//               </div>
//               <div class="footer-para">
//               <strong>CS Education</strong> is a well-established college that has provided comprehensive coaching and tutoring services to students since 2002. Its aim is to improve the educational opportunities available to students in Australia and other regions in the Asia-Pacific. 
//               </div>
//               <div class="social-icons">
//                 <a href="">
//                   <FontAwesomeIcon
//                     icon="fa-brands fa-google"
//                     style={{ color: "#fff" }}
//                   />
//                 </a>
//                 <a href="">
//                   <FontAwesomeIcon
//                     icon="fa-brands fa-linkedin-in"
//                     style={{ color: "#fff" }}
//                   />
//                 </a>
//                 <a href="">
//                   <FontAwesomeIcon
//                     icon="fa-brands fa-facebook-f"
//                     style={{ color: "#fff" }}
//                   />
//                 </a>
//                 <a href="">
//                   <FontAwesomeIcon
//                     icon="fa-brands fa-twitter"
//                     style={{ color: "#fff" }}
//                   />
//                 </a>
//               </div>
//             </div>
//             <div class="col-md-9 col-12">
            
//             <div className="row">
//                                 <div className="event">
//                                     <h2 className="All e-Test list">Upcoming Events</h2>
//                                 </div>
//                                 <div className="col-12">
//                                     <div className="sch_event">
//                                         {
//                                             events && events?.map((e) => {
//                                                 return (
//                                                     <>
//                                                         <div className="sch_event_main">
//                                                             <div className="sch_event_inner">
//                                                                 <div className="july_num">
//                                                                     <h2>{e?.date}</h2>
//                                                                     <h4>{e?.month}</h4>
//                                                                 </div>
//                                                                 <div className="evnt_head">
//                                                                     <h2>{e?.title}</h2>
//                                                                     <h4>{e?.date_time}</h4>
//                                                                 </div>
//                                                             </div>
//                                                             <div className="sch_view">
//                                                             <button onClick={() =>( (,(e?.id))}>View</button>
//                                                             </div>
//                                                         </div>
//                                                         <hr />
//                                                     </>
//                                                 )
//                                             })
//                                         }
//                                     </div>
//                                 </div>
//                             </div>
                               


//             </div>
//             {footerData?.map((data, key) => (
//               <FooterColumn key={key} title={data.title} links={data.list} />
//             ))}
//           </div>
//         </div>
//       </section>

//       <section class="cpyrt-sec">
//         <div class="cpyrt-inner container">
//           <span class="cpyrt-txt">
//             © Copyright 2007-2023 CS Education & CS Online School
//           </span>

//           <span class="cpyrt-txt">Our Branches : VIC | QLD | SA</span>
//         </div>
//       </section>
//     </>
//   );
// }

// export default Footer;

// const FooterColumn = ({ title, links }) => (
//   <div className="footer-col">
//     {title && <div className="footer-col-title">{title}</div>}
//     <div className="footer-list">
//       <ul>
//         {links.map((link, index) => (
//           <li key={index}>
//             <Link to={link.url}>
//               {capitalizeFirstLetter(link.label)}
//               {link.content &&
//                 link.content.map((item, idx) => (
//                   <span key={idx}>
//                     &nbsp;{item}
//                     {link.content.length > 1 && link.content.length - 1 != idx
//                       ? ","
//                       : ""}
//                     &nbsp;
//                   </span>
//                 ))}
//             </Link>
//           </li>
//         ))}
//       </ul>
//     </div>
//   </div>
// );


import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import images from "../../../assets";
import { apiRequest, capitalizeFirstLetter } from "../../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get_events } from "../../../apis/Dashboard";
import EventDetail from "../../../pages/Event/EventDetail";
import BookEvent from "../../../pages/Event/BookEvent";

function Footer() {
  const [footerData, setFooterData] = useState([]);
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id,setId]=useState('');

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    async function get_data() {
      const event = await get_events();
      setEvents(event?.data);
    }
    get_data();
  }, []);

  const fetchData = async () => {
    try {
      const response = await apiRequest("/api/footer-sections/get-list", "GET");
      console.log("res", response);
      setFooterData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleViewClick = (e) => {
    setId(e?.id);
    setOpen(true);
  };
  return (
    <>
      <section className="footer-sec">
        <div className="footer-inner container">
          <div className="footer-grid-box">
            <div className="col-md-3 col-12">
              <div className="footer-logo">
                <img {...images["footer-logo"]} alt="Footer Logo" />
              </div>
              <div className="footer-para">
                <strong>CS Education</strong> is a well-established college that has provided comprehensive coaching and tutoring services to students since 2002. Its aim is to improve the educational opportunities available to students in Australia and other regions in the Asia-Pacific.
              </div>
              <div className="social-icons">
                <a href="">
                  <FontAwesomeIcon icon="fa-brands fa-google" style={{ color: "#fff" }} />
                </a>
                <a href="">
                  <FontAwesomeIcon icon="fa-brands fa-linkedin-in" style={{ color: "#fff" }} />
                </a>
                <a href="">
                  <FontAwesomeIcon icon="fa-brands fa-facebook-f" style={{ color: "#fff" }} />
                </a>
                <a href="">
                  <FontAwesomeIcon icon="fa-brands fa-twitter" style={{ color: "#fff" }} />
                </a>
              </div>
            </div>
            <div className="col-md-4 col-12">

            </div>
            <div className="col-md-5 col-12">
              <div className="row">
                <div className="event">
                  <h2 className="All e-Test list">Upcoming Events</h2>
                </div>
                <div className="col-12">
                  <div className="sch_event pt-0">
                    
                    {events && events.map((e) => (
                      <div key={e?.id} className="sch_event_main">
                        <div className="sch_event_inner">
                          <div className="july_num">
                            <h2>{e?.date}</h2>
                            <h4>{e?.month}</h4>
                          </div>
                          <div className="evnt_head">
                            <h2>{e?.title}</h2>
                            <h4>{e?.date_time}</h4>
                          </div>
                        </div>
                        <div className="sch_view">
                        <button onClick={() =>{ setOpen(true);setId(e?.id)}}>View</button>
                        </div>
                        
                      </div>
                    ))}
                    
                  </div>
                </div>
              </div>
            </div>
            {footerData.map((data, key) => (
              <FooterColumn key={key} title={data.title} links={data.list} />
            ))}
          </div>
        </div>
        <EventDetail open={open} setOpen={setOpen} id={id} openBokingModal={setIsModalOpen} />
        <BookEvent   setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} id={id} />
      </section>

      <section className="cpyrt-sec">
        <div className="cpyrt-inner container">
          <span className="cpyrt-txt">
            © Copyright 2007-2023 CS Education & CS Online School
          </span>
          <span className="cpyrt-txt">Our Branches : VIC | QLD | SA</span>
          <div>
          <span class="cpyrt-txt">
            {" "}
            <Link to="/about-us">About US</Link>
          </span>
          <span class="cpyrt-txt">
            <Link to="/privacy-policy">Privacy Policy</Link>
          </span>
          <span class="cpyrt-txt">
            <Link to="/contact-us">Contact Us</Link>
          </span>
          </div>
        </div>
      </section>
    </>
  );
}

const FooterColumn = ({ title, links }) => (
  <div className="footer-col">
    {title && <div className="footer-col-title">{title}</div>}
    <div className="footer-list">
      <ul>
        {links.map((link, index) => (
          <li key={index}>
            <Link to={link.url}>
              {capitalizeFirstLetter(link.label)}
              {link.content && link.content.map((item, idx) => (
                <span key={idx} className="footer-sub-item">{item}</span>
              ))}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default Footer;