import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { events_edit } from "../../apis/Dashboard";
import { Modal } from "react-bootstrap";
import "./Event.css";
const EventView = ({ open, setOpen, id, openBokingModal }) => {
  const [eventData, setEventData] = useState({});
  const [show, setShow] = useState(false);
  const fetchData = async () => {
    let res = await events_edit(id);
    const event = res?.data[0];
    setEventData(event);
    setShow(event?.event_type === "interview");
  };
  useEffect(() => {
    if(id){
      fetchData();
    }
  }, [id]);
  const handlePopUp = () => {
    openBokingModal(true);
    setOpen(false);
  };
  return (
    <>
      <Modal
        show={open}
        onHide={() => setOpen(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="event-popup"
      >
        <div className="right-nav" id="dash-event">
          <div className="modal-header">
            <h4 className="modal-title">Event Details</h4>
            <button
              type="button"
              onClick={() => setOpen(false)}
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
          <div className="seminar">
            <div className="seminar_header">
              <span>
                Event #{eventData?.id || "N/A"}-{" "}
                {eventData?.event_type || "N/A"}
              </span>
              <h3>{eventData?.title || "N/A"}</h3>
              <p>{eventData?.description || "N/A"}</p>
            </div>
            {/* <div className="semonar_topics">
            <p>Seminar Topics:</p>
            <li>Updated News (Exam trends)</li>
            <li>School Choices</li>
            <li>Preparation for school tests and Selective High Schools test</li>
        </div> */}
            <div>
              <p>Date & Time:</p>
              <p>
                {eventData?.date || "N/A"}, {eventData?.time_start || "N/A"}-
                {eventData?.time_end || "N/A"}
              </p>
            </div>
            <div className="venue_sec">
              <h5 className="venue_space">Venue</h5>
              <div className="face_toseats">
                <div>
                  <p>
                    Face-to-Face:{" "}
                    <span>{eventData?.offine_seats || "N/A"} seats</span>
                  </p>
                  <p>
                    CS Education <span>{eventData?.branch_name || "N/A"}</span>
                  </p>
                  <p>{eventData?.offine_address || "N/A"}</p>
                </div>
                <div>
                  <p>
                    Online:{" "}
                    <span>{eventData?.online_seats || "N/A"} seats</span>
                  </p>
                  <p>(Email will be sent with online address.)</p>
                </div>
              </div>
            </div>
            <div className="seminar_footer">
              <p>
                {" "}
                <span>
                  Available seats: (Offline){" "}
                  {eventData?.offline_available_seats || "N/A"} seats
                </span>{" "}
                | (Online) {eventData?.online_available_seats || "N/A"} seats{" "}
              </p>
              <p>** The number of available seats may vary.</p>
              <p>
                <span>{eventData?.booking_date_end || "N/A"}</span>
              </p>
            </div>
            <div className="venuebtn">
              <button
                type="button"
                title="BOOK NOW"
                onClick={() => {
                  handlePopUp();
                }}
              >
                BOOK NOW
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EventView;
