import React, { useEffect } from "react";
import './policy.css';
import PolicyBanner from "../../components/PolicyBanner";
import PolicyBanner1 from "../../assets/images/PolicyBanner1.png";
import PolicyBanner2 from "../../assets/images/PolicyBanner2.png";
import PolicyBanner3 from "../../assets/images/PolicyBanner3.png";
import { Header,Footer } from "../../components";
import { useLocation } from "react-router-dom";
const Aboutus = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);
  return (
    <>
    <Header />
    <PolicyBanner title={'About Us'}/>
      <section className="about_cs_sec">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="ab_he">
                        <h2>Welcome to <span>CS Online School !</span> </h2>
                    </div>
                    <div className="css_online">
                        <p>CS Online School is a company specialising in online education, using modem technology to
                            bring many years of experience <br /> to today's students.</p>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-6">
                    <div className="school_helps">
                        <div className="edu">
                            <p className="providing_online_pro">CS Online school helps students and parents by developing
                                education material and providing online programs, supplemented with the support of our
                                experienced teachers.</p>
                            <p className="providing_online_pro">In addition, we aim to provide more opportunities to
                                students by allowing them to learn at any time and from any place via our online school.
                            </p>
                            <p className="providing_online_pro">We are committed to working with you so that you
                                achiere your ambition.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="css_online_image">
                        <img src={PolicyBanner1} alt="" />
                        {/* <!-- <img src="images/student.png" alt=""> --> */}
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="coun_sec">
        <div className="container">
            <div className="row">
                <div className="col-md-3 col-12">
                    <div className="counter">
                        <h2>45.2K</h2>
                        <a href="#" title="Student Enrolled">Student Enrolled</a>
                    </div>
                </div>
                <div className="col-md-3 col-12">
                    <div className="counter">
                        <h2>32.4K</h2>
                        <a href="#" title="Student Enrolled">Classes Completed</a>
                    </div>
                </div>
                <div className="col-md-3 col-12">
                    <div className="counter">
                        <h2>354+</h2>
                        <a href="#" title="Student Enrolled">Top Online Instructors</a>
                    </div>
                </div>
                <div className="col-md-3 col-12">
                    <div className="counter">
                        <h2>99.9%</h2>
                        <a href="#" title="Student Enrolled">Satisfaction Rate</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="our_vision_sec mb-5">
        <div className="container vision_border">
            <div className="row">
                <div className="col-md-5 col-12">
                    <div className="our_vision_img">
                        <img src={PolicyBanner2} alt="" />
                        <div className="icon_dots">
                            <img src={PolicyBanner3} alt="" />
                        </div>
                    </div>
                </div>
                <div className="col-md-7 col-12">
                    <div className="our_vision_text">
                        <h2>Our <span>Vision</span></h2>
                        <p><b>CS Education </b> brings together everything needed for a <b>Total Education Solution.</b> 
                            We aims to offer an educational solution providing everything necessary to equip students with an education equal to the best in the world in the 21st century. In order to do this we will select the best systems, the most helpful books, and the latest ideas from educators all around the world, and adapt them where necessary for use in Australia.</p>
                            <p>It is our intention to bring something new to education in Australia, to provide a platform on which we will develop new education systems and methods, and thus to advance the quality of education so that our students have a real advantage.</p>
                            <p>Whilst we expect our students to excel in the Australia education system, our aim goes far beyond this. We intend to provide here in Australia a system of education in line with the best in the world.</p>
                            <p>You will find that the different parts of our website are complementary; they work together to ensure the integrity of our system. <br />
                                We do not just recommend books or advise tuition – CS Education brings together everything needed for a <b>Total Education Solution.</b> </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="about_contant">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="provide_various">
                        <p>We provide various educational classes and tests which students can access whenever and
                            wherever they want. <br /> Students can purchase online tests and lessons, and can access each
                            item as many times as they wish for a certain period of time (usually 90 days), allowing
                            them to thoroughly understand and digest its contents. <br /> This helps them review what they
                            have learned at school or at our coaching college, and ensures they memorise the important
                            points of each class.</p>
                        <p>Students can enrol in a variety of online programs, Including our comprehensive assessment
                            test, our Basic Skills Trial Test, our OC and Selective School Trial Tests and various class
                            lesson and tests. <br /> Students receive immediate results and detailed answer sheets as soon
                            as they finish the exams.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
    </>
  );
};

export default Aboutus;
